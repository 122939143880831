<template>
  <!-- Table Container Card -->
  <div>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <!-- Search Game-->
          <b-col
            cols="6"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="competition"
                :options="allGames"
                label="c_string_swe"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_GAME')"
                @input="changeCompetitionClick()"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <section id="feather-icons">
      <div
        id="icons-container"
        class="d-flex flex-wrap"
      >
        <b-card
          v-for="charity in charities"
          :key="charity.ID"
          v-b-tooltip.hover.top="charity.name"
          class="icon-card cursor-pointer text-center mb-2 mx-50"
          @click="addorDeleteCompetitionCharity(charity)"
        >
          <div class="icon-wrapper">
            <b-avatar
              rounded
              size="95"
              variant="light-info"
            >
              <b-img
                :src="charity.logourl"
                alt="avatar img"
                width="90px"
                height="90px"
              /></b-avatar>
          </div>
          <b-card-text class="icon-name text-truncate mb-0 mt-1">
            <feather-icon
              v-if="charity.isGifted && canEditCharity"
              class="text-primary"
              icon="GiftIcon"
              size="24"
            />
            <feather-icon
              v-if="charity.isGifted && !canEditCharity"
              class="text-success"
              icon="GiftIcon"
              size="24"
            />
          </b-card-text>
        </b-card>
      </div>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardText, VBTooltip, BImg,
  BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { canEditDeleteGame } from '@/constants/utils'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BCardText,
    vSelect,
    BImg,
    BAvatar,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      canEditCharity: true,
      charities: [],
      competition: useJwt.getGame(),
    }
  },
  computed: {
    ...mapState('game', ['allCharities', 'allGameCharities', 'allGames']),
  },
  created() {
    this.fetchAllGames().then(() => {
      this.fetchAllCharities().then(() => {
        if (router.currentRoute.params.id) {
          this.competition = this.allGames.find(i => i.encrypt_id === router.currentRoute.params.id)
        }
        this.changeCompetitionClick()
      })
    })
  },
  methods: {
    ...mapActions('game', ['fetchAllCharities', 'fetchAllGameCharities', 'fetchAllGames', 'createCompetitionCharity', 'deleteCompetitionCharity']),
    fetchCompetitionCharity(id) {
      try {
        this.fetchAllGameCharities(id).then(() => {
          this.mapCompetitionCharity()
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
    changeCompetitionClick() {
      if (this.competition.encrypt_id) {
        this.fetchCompetitionCharity(this.competition.encrypt_id)
        useJwt.setGame(this.competition)
        this.canEditCharity = canEditDeleteGame(this.competition)
      }
    },
    addorDeleteCompetitionCharity(charity) {
      if (this.canEditCharity) {
        if (charity.isGifted) {
          this.deleteCompetitionCharity(charity.competition_charity_id).then(() => {
            this.successMessage(this.$i18n.t('MESSAGE.CHARITY_DELETED'))
            this.changeCompetitionClick()
          })
        } else {
          const payload = { charity_id: charity.ID, comp_id: this.competition.ID }
          this.createCompetitionCharity(payload).then(() => {
            this.successMessage(this.$i18n.t('MESSAGE.CHARITY_CREATED'))
            this.changeCompetitionClick()
          })
        }
      }
    },
    mapCompetitionCharity() {
      try {
        this.charities = JSON.parse(JSON.stringify(this.allCharities))
        if (this.allGameCharities.length > 0) {
          this.allGameCharities.forEach(e => {
            const matchingGameCharity = this.charities.find(element => element.ID === e.charity_id)
            if (matchingGameCharity) {
              matchingGameCharity.competition_charity_id = e.ID
              matchingGameCharity.isGifted = true
            }
          })
        }
      } catch (error) {
        this.showErrorMessage()
      }
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
